<template>
  <v-container>
    <MainForm/>
    <v-divider class="no-screen divider" />
    <MainForm class="no-screen" />
  </v-container>
</template>

<script>
import MainForm from './MainForm.vue';
export default {
  components: {
    MainForm
  },
  beforeCreate() {
    this.$store.commit('main/ADD_DATE', new Date().toISOString().substr(0, 10)); 
  },
}
</script>



