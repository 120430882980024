import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './styles/main.css'
import store from './store/index.js'

Vue.use(Vuex)
Vue.prototype.$store = store;
Vue.config.productionTip = false


new Vue({
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');

