export function getformattedDate(date) {
  if (date) {
    const [yyyy, mm, dd] = date.split('-').map((p) => parseInt(p));
    return dd.toString().padStart(2, '0') + '.' + mm.toString().padStart(2, '0') + '.' + yyyy;
  } else {
    return '';
  }

}

export function getDate(e) {
  if (e) {
    if (/^(0[1-9]|[12][0-9]|3[01]|[1-9])[- /.](0[1-9]|1[012]|[1-9])[- /.](19|20)[22-99]/.test(e)) {
      const [dd, mm, yyyy] = e.split('.').map((p) => parseInt(p));
      return yyyy + '-' + mm.toString().padStart(2, '0') + '-' + dd.toString().padStart(2, '0');
    }
  }
}
