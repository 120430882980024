import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,[_c('div',{staticClass:"flex-col items-center"},[_c(VSelect,{staticClass:"main-select mt-4 mb-1 mb no-print custom-select",staticStyle:{"padding-bottom":"0"},attrs:{"hide-details":"","outlined":"","items":_vm.options},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}}),_c(_vm.currentFormComponent,{tag:"component"})],1),_c(VBtn,{staticClass:"pa-3 mx-5 no-print mb-2",attrs:{"outlined":""},on:{"click":_vm.clearForm}},[_vm._v("usuń dane")]),_c(VBtn,{staticClass:"no-print mb-2",attrs:{"outlined":""},on:{"click":_vm.printForm}},[_vm._v("drukuj")])],1),_c(VFooter,{staticClass:"no-print mt-2",attrs:{"padless":""}},[_c(VCol,{staticClass:"text-center black footer",attrs:{"cols":"12"}},[_vm._v(" Copyright © "+_vm._s(new Date().getFullYear())+" "),_c('a',{attrs:{"href":"https://www.codefusion.pl"}},[_vm._v("CODEFUSION Sp. z o.o. ")]),_vm._v("All rights reserved. ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }