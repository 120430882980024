<template>
  <v-container class="component">
    <SecondaryForm :show-border="false"/>
    <v-divider class="my-2 no-screen" />
    <SecondaryForm :show-border="false" class="no-screen" />
  </v-container>
</template>

<script>
import SecondaryForm from './SecondaryForm.vue';
export default {
  components: {
    SecondaryForm
  },
  beforeCreate() {
    this.$store.commit('main/ADD_DATE', new Date().toISOString().substr(0, 10)); 
  },
}
</script>



