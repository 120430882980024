export const getDayholidays_easter = (rok) => {
    // wielka noc
    let a = rok % 19;
    let b = rok % 4;
    let c = rok % 7;
    let d = (19 * a + 24) % 30;
    let e = (2 * b + 4 * c + 6 * d + 5) % 7;
    let day = 22 + d + e;

    if (rok === 1954 || rok === 1981 || rok === 2049 || rok === 2076) {
        day += 7;
    }

    if (day <= 31) {
        return { day: day, month: 3}
    } else {
        day -= 31;
        return { day: day, month: 4}
    }
  }

  export const getDayholidays_Wet_Monday= (rok) => {
    // Wielkanocny Poniedziałek
    let easter = getDayholidays_easter(rok);
    let date_easter = new Date(rok, easter.month - 1, easter.day);
    let wet_Monday = new Date(date_easter);
    wet_Monday.setDate(date_easter.getDate() + 1); 
    return { day: wet_Monday.getDate(), month: wet_Monday.getMonth() + 1};
}

export const getDayholidays_Corpus_Christi = (rok) => {
    let easter = getDayholidays_easter(rok);
    let date_easter = new Date(rok, easter.month - 1, easter.day);
    let corpus_Christi = new Date(date_easter);
    corpus_Christi.setDate(date_easter.getDate() + 60); // 60 dni po Wielkanocy

    return { day: corpus_Christi.getDate(), month: corpus_Christi.getMonth() + 1};
}

export const getAllDates = (rok) => {
    const holidays_easter =  getDayholidays_easter(rok)
    const holidays_Wet_Monday = getDayholidays_Wet_Monday(rok)
    const holidays_Corpus_Christi = getDayholidays_Corpus_Christi(rok)

    return [ holidays_easter, holidays_Wet_Monday, holidays_Corpus_Christi ]
}

// święta ruchome:
// {
//     "day": 30,
//     "month": 5 
//   },
//   {
//     "day": 31,
//     "month": 3
//   },
//   {
//     "day": 1,
//     "month": 4
//   },