export default {
    namespaced: true,
    state: {
        name: '',
        position: '',
        city: '',
        date: '',
        inDate: '',
        day: '',
        forDate: '',
        signature: ''
    },
    mutations: {
        ADD_DAY(state, day) {
            state.day = day;
        },
        ADD_NAME(state, name) {
            state.name = name;
        },
        ADD_CITY(state, city) {
            state.city = city;
        },
        ADD_POSITION(state, position) {
            state.position = position;
        },
        ADD_DATE(state, date) {
            if (date == undefined) {
                state.date = null
            } else {
                state.date = date;
            }
        },
        ADD_FOR(state, forDate) {
            if (forDate == undefined) {
                state.forDate = null
            } else {
                state.forDate = forDate;
            }
        },
        ADD_IN(state, inDate) {
            if (inDate == undefined) {
                state.inDate = null
            } else {
                state.inDate = inDate;
            }
        },
        ADD_SIG(state, signature) {
            state.signature = signature;
        },
        initialiseStore(state) {
            if (localStorage.getItem('store')) {
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem('store')))
                );
            }
        },
        currentDate(state) {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0');
            let yyyy = today.getFullYear();
            state.date = yyyy + '-' + mm + '-' + dd
        },
        defaultDay(state) {
            state.day = 'sobota'
        },
        clearStore(state) {
            Object.keys(state).forEach(key => {
                state[key] = null
            });
        }
    },
}