<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
    </template>
    <v-date-picker class="v-date-picker" ref="datePicker" scrollable :first-day-of-week="1" header-color="primary" v-model="picker" @click="menu = false" :locale="locale" >
    </v-date-picker>
    <v-btn class="w-full" @click="reset">Dzisiaj</v-btn>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: new Date().toISOString().substr(0, 10),
    },
  },
  data() {
    return {
      locale: "pl"
    };
  },
  methods: {
    reset() {
      this.$emit("input", null);
      
      setTimeout(() => {
        this.$emit("input", new Date().toISOString().substr(0, 10));
      }, 0);

      this.$emit("input", null);
    },
  },
  computed: {
    picker: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.menu = false;
      },
    },
  },
};
</script>