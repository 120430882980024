<template>
  <v-container class="form-cont">
    <v-row same no-gutters class="row-1">
      <v-col>
        <v-text-field v-model="name" hint="Imię i nazwisko pracownika" persistent-hint flat solo></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex">
        <v-text-field flat solo v-model="city"></v-text-field>
        <p class="mx-1 pt-3">dnia</p>
        <v-text-field :value="formattedDate" @blur="date = getDate($event)" flat solo>
          <template v-slot:append-outer>
            <date-picker v-model="date" />
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="row-1">
      <v-col cols="4">
        <v-text-field hint="Stanowisko" persistent-hint flat solo v-model="position"></v-text-field>
      </v-col>
    </v-row>
    <v-row style="color: #373d40;">
      <v-col>
        <h1>Wniosek o udzielenie dnia wolnego </h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
      Proszę o udzielenie:
    </v-row>
    <v-row no-gutters class="row-2">
      <p> Dnia wolnego</p>
      <v-text-field :value="formattedInDate" @blur="inDate = getDate($event)" flat solo class="input-date mr-2">
        <template v-slot:append-outer>
          <date-picker v-model="inDate" />
        </template>
      </v-text-field>
      <p>za</p>
      <v-text-field :value="formattedForDate" @blur="forDate = getDate($event)" flat solo class="input-date mr-2">
        <template v-slot:append-outer>
          <date-picker v-model="forDate" />
        </template>
      </v-text-field>
      <p>(</p>
      <v-col cols="2">
        <v-text-field persistent-hint flat solo v-model="day.text" readonly></v-text-field>
      </v-col>
      <p>).</p>
    </v-row>
    <v-row no-gutters class="row-3" justify="space-around">
      <v-col cols="3" class="d-inline-flex">
        <v-text-field hint="podpis przełożonego" persistent-hint flat solo readonly>
          <template #prepend-inner>
            <div style="width:10px; height: 70px;">
            </div>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="4" class="d-inline-flex">
        <v-text-field flat solo hint="podpis pracownika" persistent-hint readonly>
          <template #prepend-inner>
            <div style="width:10px; height: 70px;">
              <img :src="signature" height="65px" class="img-sign mx-7" alt="">
            </div>
            <v-file-input v-model="image" @change="previewFile" hide-input></v-file-input>
          </template>
        </v-text-field>
        <v-btn icon class="no-print mt-3" @click="signature = ''" plain>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="row-3" justify="space-around">
    </v-row>
  </v-container>

</template>

<script>
import DatePicker from './DatePicker.vue'
import { getformattedDate, getDate } from './date.utils';

export default {
  components: {
    DatePicker
  },
  computed: {
    name: {
      get() {
        return this.$store.state.secondary.name
      },
      set(val) {
        this.$store.commit('secondary/ADD_NAME', val)
      }
    },
    city: {
      get() {
        return this.$store.state.secondary.city
      },
      set(val) {
        this.$store.commit('secondary/ADD_CITY', val)
      }
    },
    position: {
      get() {
        return this.$store.state.secondary.position
      },
      set(val) {
        this.$store.commit('secondary/ADD_POSITION', val)
      }
    },
    date: {
      get() {
        return this.$store.state.main.date
      },
      set(val) {
        this.$store.commit('main/ADD_DATE', val); 
      }
    },
    signature: {
      get() {
        return this.$store.state.secondary.signature
      }
    },
    day: {
      get() {
        return this.$store.state.secondary.day
      },
      set(val) {
        this.$store.commit('secondary/ADD_DAY', val)
      }
    },
    inDate: {
      get() {
        return this.$store.state.secondary.inDate
      },
      set(val) {
        this.$store.commit('secondary/ADD_IN', val)
      }
    },
    forDate: {
      get() {
        return this.$store.state.secondary.forDate
      },
      set(val) {
        this.$store.commit('secondary/ADD_FOR', val)
      }
    },
    formattedDate: {
      get() {
        return getformattedDate(this.date)
      },
      set(val) {
        this.$store.commit('secondary/ADD_DATE', val)
      }
    },
    formattedInDate: {
      get() {
        return getformattedDate(this.inDate)
      },
      set(val) {
        this.$store.commit('secondary/ADD_END', val)
      }
    },
    formattedForDate: {
      get() {
        return getformattedDate(this.forDate)
      },
      set(val) {
        this.$store.commit('secondary/ADD_START', val)
      }
    },
  },
  data: () => ({
    image: null,
    options: [
      { id: 1, text: "niedziela" },
      { id: 2, text: "poniedziałek" },
      { id: 3, text: "wtorek" },
      { id: 4, text: "środa" },
      { id: 5, text: "czwartek" },
      { id: 6, text: "piątek" },
      { id: 7, text: "sobota" }
    ],
  }),
  beforeCreate() {
    this.$store.commit('secondary/defaultDay');
    this.$store.commit('secondary/initialiseStore');
  },
  methods: {
    previewFile() {
      const store = this.$store
      const file = this.image;
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        store.commit('secondary/ADD_SIG', reader.result)
      }, false);
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    getDate: (e) => getDate(e.target.value),

    changeSelectedDay() {
      
    }
  },
  mounted() {
    if(this.forDate) {
      if (this.forDate) {
        const dayOfWeek = new Date(this.forDate).getDay() + 1;
        const selectedOption = this.options.find(option => option.id === dayOfWeek);
        if (selectedOption) {
          this.day = selectedOption;
        }
      }
    }
  },
  watch: {
    forDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        const dayOfWeek = new Date(newValue).getDay() + 1;
        const selectedOption = this.options.find(option => option.id === dayOfWeek);
        if (selectedOption) {
          this.day = selectedOption;
        }
      }
    }}
}
</script>



