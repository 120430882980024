<template>
  <v-container class="form-cont">
    <v-row no-gutters class="row-1">
      <v-col>
        <v-text-field v-model="name" hint="Imię i nazwisko pracownika" persistent-hint flat solo></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex">
        <v-text-field flat solo v-model="city" class="input-city"></v-text-field>
        <p class="mx-1 pt-3">dnia</p>
        <v-text-field :value="formattedDate" flat solo @blur="date = getDate($event)">
          <template v-slot:append-outer>
            <date-picker v-model="date" />
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="row-1">
      <v-col cols="4">
        <v-text-field hint="Stanowisko" persistent-hint flat solo v-model="position"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1>Wniosek o urlop</h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
      Proszę o udzielenie:
    </v-row>
    <v-row class="row-2 text-left" no-gutters>
      <p>Urlopu</p>
      <v-select class="select mx-2" v-model="type" :items="options">
        <template #item="{ item }">
          <div class="flex" style="display: flex; flex-direction: column;">
            <span>{{ item.text }}</span>
            <span v-if="item.extraInfo" class="extra-info" style="color: rgb(145, 145, 145); font-size: 12px;">{{ item.extraInfo }}</span>
          </div>
        </template>
      </v-select>
      <p>w okresie od dnia</p>
      <v-text-field :value="formattedStartDate" @blur="startDate = getDate($event)" flat solo hide-details="auto" class="input-date mx-1">
        <template v-slot:append-outer>
          <date-picker v-model="startDate" />
        </template>
      </v-text-field>
      <p>do dnia</p>
      <v-text-field :value="formattedEndDate" @blur="endDate = getDate($event)" flat solo hide-details="auto" class="input-date mx-1">
        <template v-slot:append-outer>
          <date-picker v-model="endDate" />
        </template>
      </v-text-field>
      <p>włącznie tj.</p>
      <v-text-field :value="workingDays" flat solo class="input-num" hide-details="auto" readonly></v-text-field>
      <p>dni roboczych, za rok</p>
      <v-text-field v-model="year" class="input-num" flat solo hide-details="auto"></v-text-field>
      <p>.</p>
    </v-row>
    <v-row no-gutters class="row-3" justify="space-around">
      <v-col cols="3" class="d-inline-flex">
        <v-text-field hint="podpis przełożonego" persistent-hint flat solo readonly>
          <template #prepend-inner>
            <div style="width:10px; height: 70px;">
            </div>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="4" class="d-inline-flex">
        <v-text-field flat solo hint="podpis pracownika" persistent-hint readonly>
          <template #prepend-inner>
            <div style="width:10px; height: 70px;">
              <img :src="signature" height="65px" class="img-sign mx-7" alt="">
            </div>
            <v-file-input v-model="image" @change="previewFile" hide-input></v-file-input>
          </template>
        </v-text-field>
        <v-btn icon class="no-print mt-3" @click="signature = ''" plain>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="row-3" justify="space-around">
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "./DatePicker.vue";
import vacations from "../assets/vacations.json";
import { getformattedDate, getDate } from "./date.utils";
import { getAllDates } from "../../functions";

export default {
  components: {
    DatePicker,
  },
  computed: {
    name: {
      get() {
        return this.$store.state.main.name
      },
      set(val) {
        this.$store.commit('main/ADD_NAME', val)
      }
    },
    city: {
      get() {
        return this.$store.state.main.city
      },
      set(val) {
        this.$store.commit('main/ADD_CITY', val)
      }
    },
    position: {
      get() {
        return this.$store.state.main.position
      },
      set(val) {
        this.$store.commit('main/ADD_POSITION', val)
      }
    },
    date: {
      get() {
        return this.$store.state.main.date
      },
      set(val) {
        this.$store.commit('main/ADD_DATE', val); 
      }
    },
    type: {
      get() {
        return this.$store.state.main.type
      },
      set(val) {
        this.$store.commit('main/ADD_TYPE', val)
      }
    },
    year: {
      get() {
        return this.$store.state.main.year
      },
      set(val) {
        this.$store.commit('main/ADD_YEAR', val)
      }
    },
    endDate: {
      get() {
        return this.$store.state.main.endDate
      },
      set(val) {
        this.$store.commit('main/ADD_END', val)
      }
    },
    startDate: {
      get() {
        return this.$store.state.main.startDate
      },
      set(val) {
        this.$store.commit('main/ADD_START', val)
      },
    },
    signature: {
      get() {
        return this.$store.state.main.signature
      },
      set(val) {
        this.$store.commit('main/ADD_SIG', val)
      }
    },
    formattedDate: {
      get() {
        return getformattedDate(this.date)
      },
      set(val) {
        this.$store.commit('main/ADD_DATE', val)
      }
    },
    formattedEndDate: {
      get() {
        return getformattedDate(this.endDate)
      },
      set(val) {
        this.$store.commit('main/ADD_END', val)
      }
    },
    formattedStartDate: {
      get() {
        return getformattedDate(this.startDate)
      },
      set(val) {
        this.$store.commit('main/ADD_START', val)
      }
    },
    workingDays() {
      return this.getDaysArray(this.startDate, this.endDate)
    },
  },
  data: () => ({
    image: null,
    dateValidation: true,
    dayList: '',
    options: [
      { id: 1, text: "wypoczynkowego" },
      { id: 2, text: "wypoczynkowego w trybie na 'żądanie'" },
      { id: 3, text: "bezpłatnego" },
      { id: 4, text: "okolicznościowego" },
      { id: 5, text: "opieki nad dzieckiem" },
      { id: 5, text: "z powodu siły wyższej", extraInfo: "2 dni - płatność 1/2 wynagrodzenia" },
      { id: 5, text: "opiekuńczego dla najbliższych", extraInfo: "5 dni - bezpłatnie" }
    ],
    freeDays: vacations,
  }),
  beforeCreate() {
    this.$store.commit('main/initialiseStore');
    this.$store.commit('main/defaultYear');
  },
  methods: {
    previewFile() {
      const store = this.$store
      const file = this.image;
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        store.commit('main/ADD_SIG', reader.result)
      }, false);
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    getDate: (e) => getDate(e.target.value),
    isValidDate(s) {
      if (! /^20[0-9][0-9]-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])/.test(s)) {
        return false;
      }
      return true;
    },
    getDaysArray(start, end) {
      if (this.isValidDate(start) && this.isValidDate(end)) {
        let freeDays = 0;
        let arr = [];
        for (let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
          let weekend = false;
          let vacation = false;
          arr.push(new Date(dt));
          const holidays = getAllDates(new Date(dt).getFullYear())
          
          if (holidays.find((d) => d.day === new Date(dt).getDate() && d.month === new Date(dt).getMonth() + 1)) {
            vacation = true;
          }
          else if (this.freeDays.find((d) => d.day === new Date(dt).getDate() && d.month === new Date(dt).getMonth() + 1)) {
            vacation = true;
          } else if (new Date(dt).getDay() == 0 || new Date(dt).getDay() == 6) {
            weekend = true;
          }
          if (vacation || weekend) {
            freeDays += 1
          }
        }
        return arr.length - freeDays;
      } else {
        return ''
      }
    },  
  }
}
</script>
