<template>
  <v-app>
    <v-main>
      <div class="flex-col items-center">
        <v-select hide-details outlined :items="options" style="padding-bottom: 0;" v-model="version" class="main-select mt-4 mb-1 mb no-print custom-select"></v-select>
        <component v-bind:is="currentFormComponent"></component>
      </div>
      <v-btn outlined @click="clearForm" class="pa-3 mx-5 no-print mb-2">usuń dane</v-btn>
      <v-btn outlined @click="printForm" class="no-print mb-2">drukuj</v-btn>
    </v-main>
    <v-footer padless class="no-print mt-2">
      <v-col class="text-center black footer" cols="12">
        Copyright © {{ new Date().getFullYear() }} <a href="https://www.codefusion.pl">CODEFUSION Sp. z o.o. </a>All
        rights reserved.
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import PrintMain from './components/PrintMain.vue'
import PrintSecondary from './components/PrintSecondary.vue';

export default {
  name: 'App',
  data() {
    return {
      unsubscribe: '',
      options: [{ text: 'Wniosek o urlop', value: 'Main' }, { text: 'Wniosek o udzielenie dnia wolnego', value: 'Secondary' }],
      version: 'Main',
    }
  },
  components: {
    PrintMain,
    PrintSecondary,
  },
  methods: {
    printForm() {
      window.print();
    },
    clearForm() {
      this.$store.commit(this.version.toLowerCase() + '/clearStore');
      this.$store.commit(this.version.toLowerCase() + '/currentDate')
    },
  },
  computed: {
    currentFormComponent: function () {
      return "Print" + this.version;
    },
  },
  beforeMount() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      localStorage.setItem('store', JSON.stringify(state));
    })
  },
  beforeUnmount() {
    this.unsubscribe();
  }
}
</script>
