export default {
    namespaced: true,
    state: {
        day: '',
        type: '',
        name: '',
        position: '',
        city: '',
        date: '',
        year: '',
        startDate: null,
        endDate: '',
        inDate: '',
        forDate: '',
        signature: null
    },
    mutations: {
        ADD_DAY(state, day) {
            state.day = day;
        },
        ADD_TYPE(state, type) {
            state.type = type;
        },
        ADD_NAME(state, name) {
            state.name = name;
        },
        ADD_CITY(state, city) {
            state.city = city;
        },
        ADD_POSITION(state, position) {
            state.position = position;
        },
        ADD_DATE(state, date) {
            if (date == undefined) {
                state.date = null
            } else {
                state.date = date;
            }
        },
        ADD_YEAR(state, year) {
            state.year = year;
        },
        ADD_END(state, endDate) {
            if (endDate == undefined) {
                state.endDate = null
            } else {
                state.endDate = endDate;
            }
        },
        ADD_START(state, startDate) {
            if (startDate == undefined) {
                state.startDate = null
            } else {
                state.startDate = startDate;
            }
        },
        ADD_FOR(state, forDate) {
            state.forDate = forDate;
        },
        ADD_IN(state, inDate) {
            state.inDate = inDate;
        },
        ADD_SIG(state, signature) {
            state.signature = signature;
        },
        currentDate(state) {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0');
            let yyyy = today.getFullYear();
            state.date = yyyy + '-' + mm + '-' + dd
        },
        initialiseStore() {
            if (localStorage.getItem('store')) {
                this.replaceState(
                    JSON.parse(localStorage.getItem('store'))
                );
            }
        },
        defaultYear(state) {
            let today = new Date();
            let yyyy = today.getFullYear();
            state.year = yyyy
        },
        clearStore(state) {
            Object.keys(state).forEach(key => {
                state[key] = null
            });
        }
    },
}