import Vuex from 'vuex'
import Vue from 'vue'
import main from '../modules/main'
import secondary from '../modules/secondary'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        main,
        secondary,
    },
})